import { createIcon } from "@chakra-ui/icons";

export const RetryIcon = createIcon({
  displayName: "RetryIcon",
  defaultProps: {
    width: "14px",
    height: "15px",
  },
  viewBox: "0 0 14 15",
  path: (
    <>
      <g clipPath="url(#clip0_844_9385)">
        <path
          d="M0.583252 2.8335V6.3335H4.08325"
          stroke="currentColor"
          fill="none"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.04742 9.24993C2.42565 10.3235 3.14253 11.245 4.09005 11.8757C5.03757 12.5064 6.1644 12.8121 7.30075 12.7467C8.4371 12.6813 9.52142 12.2484 10.3903 11.5131C11.2592 10.7779 11.8657 9.78017 12.1182 8.67031C12.3708 7.56045 12.2558 6.39857 11.7906 5.35974C11.3254 4.3209 10.5352 3.46139 9.53907 2.91071C8.54292 2.36002 7.39478 2.148 6.26765 2.30658C5.14052 2.46516 4.09545 2.98576 3.28992 3.78993L0.583252 6.33326"
          stroke="currentColor"
          fill="none"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_844_9385">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </>
  ),
});
