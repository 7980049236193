import { createIcon } from "@chakra-ui/icons";

export const MyOrdersicon = createIcon({
  displayName: "MyOrdersicon",
  defaultProps: {
    width: "20px",
    height: "20px",
  },
  viewBox: "0 0 20 20",
  path: (
    <>
      <g clipPath="url(#clip0_715_20176)">
        <path
          d="M15.0007 0.833344H5.00065C4.45348 0.833344 3.91166 0.941118 3.40614 1.15051C2.90061 1.35991 2.44128 1.66682 2.05437 2.05373C1.27297 2.83513 0.833984 3.89494 0.833984 5.00001V15C0.833984 15.5472 0.941758 16.089 1.15115 16.5945C1.36055 17.1 1.66746 17.5594 2.05437 17.9463C2.83577 18.7277 3.89558 19.1667 5.00065 19.1667H15.0007C15.5478 19.1667 16.0896 19.0589 16.5952 18.8495C17.1007 18.6401 17.56 18.3332 17.9469 17.9463C18.3338 17.5594 18.6408 17.1 18.8502 16.5945C19.0595 16.089 19.1673 15.5472 19.1673 15V5.00001C19.1673 4.45284 19.0595 3.91102 18.8502 3.4055C18.6408 2.89997 18.3338 2.44064 17.9469 2.05373C17.56 1.66682 17.1007 1.35991 16.5952 1.15051C16.0896 0.941118 15.5478 0.833344 15.0007 0.833344ZM11.6673 2.50001V7.50001L10.459 6.66668C10.3198 6.58725 10.1607 6.54963 10.0007 6.55834C9.83531 6.5555 9.67287 6.60191 9.53398 6.69168L8.33398 7.50001V2.50001H11.6673ZM17.5007 15C17.5007 15.6631 17.2373 16.2989 16.7684 16.7678C16.2996 17.2366 15.6637 17.5 15.0007 17.5H5.00065C4.33761 17.5 3.70173 17.2366 3.23288 16.7678C2.76404 16.2989 2.50065 15.6631 2.50065 15V5.00001C2.50065 4.33697 2.76404 3.70108 3.23288 3.23224C3.70173 2.7634 4.33761 2.50001 5.00065 2.50001H6.66732V7.50001C6.66732 7.94204 6.84291 8.36596 7.15547 8.67852C7.46803 8.99108 7.89196 9.16668 8.33398 9.16668C8.66355 9.16576 8.98545 9.06717 9.25898 8.88334L10.0007 8.39168L10.7423 8.88334C10.9931 9.05065 11.2845 9.14682 11.5856 9.16159C11.8867 9.17637 12.1862 9.10921 12.4521 8.96726C12.7181 8.8253 12.9405 8.61388 13.0958 8.3555C13.2511 8.09713 13.3334 7.80147 13.334 7.50001V2.50001H15.0007C15.6637 2.50001 16.2996 2.7634 16.7684 3.23224C17.2373 3.70108 17.5007 4.33697 17.5007 5.00001V15Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_715_20176">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});
