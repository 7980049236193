import { createIcon } from "@chakra-ui/icons";

export const NewsIcon = createIcon({
  displayName: "NewsIcon",
  defaultProps: {
    width: "18px",
    height: "18px",
  },
  viewBox: "0 0 18 18",
  path: (
    <>
      <path
        d="M10.5 5.25H4.5C4.30109 5.25 4.11032 5.32902 3.96967 5.46967C3.82902 5.61032 3.75 5.80109 3.75 6C3.75 6.19891 3.82902 6.38968 3.96967 6.53033C4.11032 6.67098 4.30109 6.75 4.5 6.75H10.5C10.6989 6.75 10.8897 6.67098 11.0303 6.53033C11.171 6.38968 11.25 6.19891 11.25 6C11.25 5.80109 11.171 5.61032 11.0303 5.46967C10.8897 5.32902 10.6989 5.25 10.5 5.25ZM10.5 8.25H4.5C4.30109 8.25 4.11032 8.32902 3.96967 8.46967C3.82902 8.61032 3.75 8.80109 3.75 9C3.75 9.19891 3.82902 9.38968 3.96967 9.53033C4.11032 9.67098 4.30109 9.75 4.5 9.75H10.5C10.6989 9.75 10.8897 9.67098 11.0303 9.53033C11.171 9.38968 11.25 9.19891 11.25 9C11.25 8.80109 11.171 8.61032 11.0303 8.46967C10.8897 8.32902 10.6989 8.25 10.5 8.25ZM10.5 11.25H4.5C4.30109 11.25 4.11032 11.329 3.96967 11.4697C3.82902 11.6103 3.75 11.8011 3.75 12C3.75 12.1989 3.82902 12.3897 3.96967 12.5303C4.11032 12.671 4.30109 12.75 4.5 12.75H10.5C10.6989 12.75 10.8897 12.671 11.0303 12.5303C11.171 12.3897 11.25 12.1989 11.25 12C11.25 11.8011 11.171 11.6103 11.0303 11.4697C10.8897 11.329 10.6989 11.25 10.5 11.25Z"
        fill="currentColor"
      />
      <path
        d="M15.75 8.25H14.25V4.5C14.25 4.00754 14.153 3.51991 13.9645 3.06494C13.7761 2.60997 13.4999 2.19657 13.1517 1.84835C12.8034 1.50013 12.39 1.22391 11.9351 1.03545C11.4801 0.846997 10.9925 0.75 10.5 0.75H4.5C4.00754 0.75 3.51991 0.846997 3.06494 1.03545C2.60997 1.22391 2.19657 1.50013 1.84835 1.84835C1.14509 2.55161 0.75 3.50544 0.75 4.5V13.5C0.75 13.9925 0.846997 14.4801 1.03545 14.9351C1.22391 15.39 1.50013 15.8034 1.84835 16.1517C2.55161 16.8549 3.50544 17.25 4.5 17.25H15C15.5967 17.25 16.169 17.0129 16.591 16.591C17.0129 16.169 17.25 15.5967 17.25 15V9.75C17.25 9.35218 17.092 8.97064 16.8107 8.68934C16.5294 8.40804 16.1478 8.25 15.75 8.25ZM2.25 13.5V4.5C2.25 3.90326 2.48705 3.33097 2.90901 2.90901C3.33097 2.48705 3.90326 2.25 4.5 2.25H10.5C11.0967 2.25 11.669 2.48705 12.091 2.90901C12.5129 3.33097 12.75 3.90326 12.75 4.5V15C12.752 15.2559 12.7977 15.5095 12.885 15.75H4.5C3.90326 15.75 3.33097 15.5129 2.90901 15.091C2.48705 14.669 2.25 14.0967 2.25 13.5ZM15.75 15C15.75 15.1989 15.671 15.3897 15.5303 15.5303C15.3897 15.671 15.1989 15.75 15 15.75C14.8011 15.75 14.6103 15.671 14.4697 15.5303C14.329 15.3897 14.25 15.1989 14.25 15V9.75H15.75V15Z"
        fill="currentColor"
      />
    </>
  ),
});
