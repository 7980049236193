import { createIcon } from "@chakra-ui/icons";

export const WhatsAppIcon = createIcon({
  displayName: "WhatsAppIcon",
  defaultProps: {
    width: "25px",
    height: "24px",
  },
  viewBox: "0 0 25 24",
  path: (
    <>
      <g id="Whatsapp" clipPath="url(#clip0_2245_22794)">
        <g id="Group">
          <g id="Layer 2">
            <g id="09.whatsapp">
              <path
                id="Vector"
                d="M12.5 24C19.1274 24 24.5 18.6274 24.5 12C24.5 5.37258 19.1274 0 12.5 0C5.87258 0 0.5 5.37258 0.5 12C0.5 18.6274 5.87258 24 12.5 24Z"
                fill="currentColor"
              />
              <g id="Group_2">
                <path
                  id="Vector_2"
                  d="M17.7908 6.70909C16.5423 5.44814 14.8843 4.67483 13.1158 4.52867C11.3474 4.38251 9.58493 4.8731 8.14635 5.91197C6.70777 6.95084 5.68778 8.46958 5.27035 10.1943C4.85293 11.9189 5.06556 13.736 5.86988 15.3177L5.08034 19.1509C5.07215 19.1891 5.07191 19.2285 5.07966 19.2667C5.0874 19.305 5.10295 19.3412 5.12534 19.3732C5.15813 19.4217 5.20495 19.459 5.25953 19.4802C5.31411 19.5014 5.37385 19.5055 5.43079 19.4918L9.18761 18.6014C10.7649 19.3853 12.5691 19.5843 14.2793 19.1628C15.9895 18.7414 17.4947 17.7268 18.5271 16.2998C19.5595 14.8727 20.0521 13.1256 19.9173 11.3694C19.7824 9.61326 19.0289 7.96186 17.7908 6.70909ZM16.6194 16.0582C15.7556 16.9196 14.6432 17.4882 13.4391 17.6839C12.235 17.8796 10.9998 17.6926 9.90761 17.1491L9.38397 16.89L7.08079 17.4355L7.08761 17.4068L7.56488 15.0886L7.30852 14.5827C6.75047 13.4867 6.5536 12.2422 6.74614 11.0275C6.93867 9.81279 7.51072 8.69017 8.38034 7.82046C9.47303 6.72811 10.9548 6.11446 12.4999 6.11446C14.0449 6.11446 15.5267 6.72811 16.6194 7.82046C16.6287 7.83113 16.6388 7.84115 16.6494 7.85046C17.7286 8.94562 18.331 10.423 18.3254 11.9605C18.3198 13.498 17.7065 14.9709 16.6194 16.0582Z"
                  fill="white"
                />
                <path
                  id="Vector_3"
                  d="M16.4147 14.3577C16.1324 14.8023 15.6865 15.3464 15.126 15.4814C14.1442 15.7186 12.6374 15.4896 10.7624 13.7414L10.7392 13.7209C9.09057 12.1923 8.66239 10.92 8.76603 9.91092C8.8233 9.33819 9.30057 8.82001 9.70285 8.48183C9.76644 8.42755 9.84186 8.3889 9.92307 8.36898C10.0043 8.34906 10.089 8.34841 10.1705 8.3671C10.252 8.38578 10.328 8.42328 10.3924 8.47659C10.4568 8.52989 10.5079 8.59753 10.5415 8.6741L11.1483 10.0377C11.1877 10.1262 11.2023 10.2236 11.1906 10.3197C11.1788 10.4158 11.1411 10.5069 11.0815 10.5832L10.7747 10.9814C10.7088 11.0636 10.6691 11.1636 10.6606 11.2686C10.6521 11.3736 10.6752 11.4787 10.7269 11.5705C10.8988 11.8718 11.3106 12.315 11.7674 12.7255C12.2801 13.1891 12.8488 13.6132 13.2088 13.7577C13.3051 13.7971 13.411 13.8067 13.5128 13.7853C13.6147 13.7639 13.7078 13.7125 13.7801 13.6377L14.136 13.2791C14.2047 13.2114 14.2901 13.1631 14.3835 13.1391C14.477 13.1152 14.5751 13.1164 14.6678 13.1427L16.1092 13.5518C16.1887 13.5762 16.2616 13.6185 16.3223 13.6753C16.383 13.7322 16.4298 13.8022 16.4593 13.88C16.4888 13.9577 16.5001 14.0412 16.4923 14.124C16.4846 14.2068 16.458 14.2868 16.4147 14.3577Z"
                  fill="white"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2245_22794">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </>
  ),
});
