import { createIcon } from "@chakra-ui/icons";

export const UserIcon2 = createIcon({
  displayName: "UserIcon2",
  defaultProps: {
    width: "22px",
    height: "22px",
  },
  viewBox: "0 0 22 22",
  path: (
    <path
      d="M15.2717 12.1091C16.242 11.2435 16.9263 10.1036 17.2342 8.84036C17.5421 7.57711 17.459 6.25012 16.9958 5.03518C16.5326 3.82024 15.7113 2.77468 14.6405 2.03699C13.5698 1.2993 12.3003 0.904297 11 0.904297C9.69981 0.904297 8.43025 1.2993 7.35954 2.03699C6.28883 2.77468 5.46748 3.82024 5.00431 5.03518C4.54113 6.25012 4.45798 7.57711 4.76586 8.84036C5.07374 10.1036 5.75813 11.2435 6.72837 12.1091C5.33382 12.4495 4.09369 13.2477 3.2063 14.3761C2.3189 15.5045 1.83549 16.8978 1.83337 18.3333V20.1666C1.83337 20.4097 1.92995 20.6429 2.10186 20.8148C2.27377 20.9867 2.50693 21.0833 2.75004 21.0833C2.99316 21.0833 3.22631 20.9867 3.39822 20.8148C3.57013 20.6429 3.66671 20.4097 3.66671 20.1666V18.3333C3.66671 17.1177 4.14959 15.9519 5.00913 15.0924C5.86868 14.2328 7.03447 13.75 8.25004 13.75H13.75C14.9656 13.75 16.1314 14.2328 16.9909 15.0924C17.8505 15.9519 18.3334 17.1177 18.3334 18.3333V20.1666C18.3334 20.4097 18.43 20.6429 18.6019 20.8148C18.7738 20.9867 19.0069 21.0833 19.25 21.0833C19.4932 21.0833 19.7263 20.9867 19.8982 20.8148C20.0701 20.6429 20.1667 20.4097 20.1667 20.1666V18.3333C20.1646 16.8978 19.6812 15.5045 18.7938 14.3761C17.9064 13.2477 16.6663 12.4495 15.2717 12.1091ZM6.41671 7.33329C6.41671 6.42679 6.68552 5.54065 7.18914 4.78693C7.69276 4.0332 8.40858 3.44574 9.24608 3.09884C10.0836 2.75194 11.0051 2.66118 11.8942 2.83802C12.7833 3.01487 13.6 3.45139 14.2409 4.09238C14.8819 4.73337 15.3185 5.55005 15.4953 6.43913C15.6722 7.32821 15.5814 8.24976 15.2345 9.08726C14.8876 9.92475 14.3001 10.6406 13.5464 11.1442C12.7927 11.6478 11.9065 11.9166 11 11.9166C9.78447 11.9166 8.61868 11.4337 7.75913 10.5742C6.89959 9.71465 6.41671 8.54887 6.41671 7.33329Z"
      fill="currentColor"
    />
  ),
});
