import { createIcon } from "@chakra-ui/icons";

export const FlagIcon = createIcon({
  displayName: "FlagIcon",
  defaultProps: {
    width: "40px",
    height: "40px",
  },
  viewBox: "0 0 40 40",
  path: (
    <g id="Frame">
      <g id="Group">
        <path
          id="Vector"
          d="M28.5335 11.6666L31.4835 5.74996C31.6113 5.49624 31.6722 5.21406 31.6603 4.9302C31.6484 4.64634 31.5642 4.37023 31.4156 4.1281C31.2669 3.88596 31.0589 3.68584 30.8112 3.54673C30.5634 3.40762 30.2843 3.33415 30.0002 3.33329H11.6668C11.6668 2.89127 11.4912 2.46734 11.1787 2.15478C10.8661 1.84222 10.4422 1.66663 10.0002 1.66663C9.55814 1.66663 9.13421 1.84222 8.82165 2.15478C8.50909 2.46734 8.3335 2.89127 8.3335 3.33329V36.6666C8.3335 37.1087 8.50909 37.5326 8.82165 37.8451C9.13421 38.1577 9.55814 38.3333 10.0002 38.3333C10.4422 38.3333 10.8661 38.1577 11.1787 37.8451C11.4912 37.5326 11.6668 37.1087 11.6668 36.6666V20H30.0002C30.2843 19.9991 30.5634 19.9256 30.8112 19.7865C31.0589 19.6474 31.2669 19.4473 31.4156 19.2052C31.5642 18.963 31.6484 18.6869 31.6603 18.4031C31.6722 18.1192 31.6113 17.837 31.4835 17.5833L28.5335 11.6666ZM25.2002 12.4166L27.3002 16.6666H11.6668V6.66663H27.3002L25.1835 10.9166C25.0663 11.1493 25.0052 11.4061 25.0052 11.6666C25.0052 11.9271 25.0663 12.184 25.1835 12.4166H25.2002Z"
          fill="currentColor"
        />
      </g>
    </g>
  ),
});
