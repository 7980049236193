import { createIcon } from "@chakra-ui/icons";

export const TimerClock = createIcon({
  displayName: "TimerClock",
  defaultProps: {
    width: "14px",
    height: "20px",
  },
  viewBox: "0 0 14 20",
  path: (
    <g id="Group">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.80876 11.3386C4.98386 11.1664 5.12333 10.9589 5.21866 10.7287C5.31398 10.4986 5.36314 10.2506 5.36314 10C5.36314 9.7494 5.31398 9.50143 5.21866 9.27127C5.12333 9.04111 4.98386 8.8336 4.80876 8.66143L4.70723 8.5617C2.81281 6.68045 1.34898 5.22845 0.90899 2.18463C0.751043 1.11201 1.63479 0.222229 2.69905 0.222229H11.3015C12.3658 0.222229 13.2495 1.11201 13.0934 2.18463C12.6516 5.22845 11.1887 6.68045 9.29426 8.5617L9.19366 8.66143C9.01857 8.8336 8.87909 9.04111 8.78377 9.27127C8.68844 9.50143 8.63928 9.7494 8.63928 10C8.63928 10.2506 8.68844 10.4986 8.78377 10.7287C8.87909 10.9589 9.01857 11.1664 9.19366 11.3386L9.29426 11.4383C11.1887 13.3196 12.6525 14.7716 13.0934 17.8154C13.2495 18.888 12.3658 19.7778 11.3015 19.7778H2.69905C1.63479 19.7778 0.751043 18.888 0.907109 17.8154C1.34898 14.7716 2.81187 13.3196 4.70629 11.4383L4.80876 11.3386ZM5.45089 12.9989L5.44337 13.0047C4.00023 14.3472 2.92563 15.3465 2.78554 17.8183C2.7814 17.89 2.79354 17.9617 2.82098 18.0276C2.84841 18.0935 2.89038 18.1518 2.94349 18.1977C3.02765 18.2715 3.13429 18.3117 3.24434 18.3111H10.7581C10.8935 18.3111 11.0157 18.2525 11.0994 18.1566C11.1398 18.1114 11.171 18.0581 11.1911 17.9999C11.2111 17.9418 11.2196 17.88 11.2159 17.8183C11.0759 15.3465 10.0022 14.3472 8.55905 13.0047C8.17735 12.6498 7.76932 12.2704 7.34625 11.8333C7.25422 11.7388 7.13008 11.6858 7.00074 11.6858C6.8714 11.6858 6.74726 11.7388 6.65523 11.8333C6.23498 12.2675 5.82977 12.6449 5.45089 12.9979V12.9989Z"
        fill="currentColor"
      />
    </g>
  ),
});
