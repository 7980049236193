import { createIcon } from "@chakra-ui/icons";

export const PlusIcon = createIcon({
  displayName: "PlusIcon",
  defaultProps: {
    width: "14px",
    height: "14px",
  },
  viewBox: "0 0 14 14",
  path: (
    <>
      <path
        d="M7 2.91666V11.0833"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.91669 7H11.0834"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});
