import { createIcon } from "@chakra-ui/icons";

export const LikeIcon = createIcon({
  displayName: "LikeIcon",
  defaultProps: {
    width: "23px",
    height: "22px",
  },
  viewBox: "0 0 23 22",
  path: (
    <path
      d="M11.5031 21.0002C10.9766 21.0006 10.4612 20.8491 10.019 20.5639C7.41464 18.8703 5.17382 16.6762 3.42709 14.1093C1.05982 10.5093 0.850409 6.02752 2.92632 3.4457C3.44758 2.72428 4.12525 2.12968 4.90878 1.70625C5.6923 1.28282 6.56146 1.04149 7.45144 1.00024C8.95761 1.06186 10.3932 1.65452 11.5031 2.67297C12.6083 1.65848 14.0367 1.0661 15.5366 1.00024C16.4296 1.03597 17.3029 1.27287 18.0912 1.6932C18.8796 2.11353 19.5625 2.70643 20.089 3.42752C22.1649 6.00934 21.91 10.4912 19.5882 14.0912C17.8415 16.658 15.6007 18.8521 12.9963 20.5457C12.5535 20.839 12.0345 20.997 11.5031 21.0002ZM7.46965 2.81843C6.84379 2.86991 6.23724 3.05977 5.69394 3.37424C5.15065 3.68871 4.68423 4.11991 4.32847 4.63661C2.77154 6.57297 3.03558 10.2366 4.9476 13.1457C6.55833 15.5039 8.62268 17.5188 11.0205 19.073C11.1652 19.1633 11.3325 19.2112 11.5031 19.2112C11.6737 19.2112 11.841 19.1633 11.9857 19.073C14.3835 17.5188 16.4479 15.5039 18.0586 13.1457C19.9706 10.2366 20.2347 6.58206 18.6686 4.62752C18.3181 4.11477 17.8576 3.68639 17.3205 3.37347C16.7835 3.06055 16.1834 2.87095 15.5639 2.81843C14.2742 2.94746 13.0808 3.55785 12.2224 4.52752C12.1369 4.6273 12.0308 4.70742 11.9113 4.76234C11.7919 4.81727 11.6619 4.84571 11.5304 4.8457C11.3989 4.84571 11.269 4.81727 11.1495 4.76234C11.03 4.70742 10.9239 4.6273 10.8384 4.52752C9.97332 3.55174 8.76906 2.94079 7.46965 2.81843Z"
      fill="currentColor"
    />
  ),
});
