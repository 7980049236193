import { createIcon } from "@chakra-ui/icons";

export const LikeFilledIcon = createIcon({
  displayName: "LikeFilledIcon",
  defaultProps: {
    width: "23px",
    height: "22px",
  },
  viewBox: "0 0 23 22",
  path: (
    <>
      <g clipPath="url(#clip0_1318_10187)">
        <path
          d="M9.00086 17.182C8.57011 17.1823 8.14844 17.0583 7.7866 16.8249C5.65575 15.4393 3.82236 13.6441 2.39321 11.5439C0.45636 8.59849 0.285022 4.93155 1.98349 2.81915C2.40998 2.22891 2.96444 1.74242 3.6055 1.39597C4.24657 1.04953 4.9577 0.852072 5.68586 0.818329C6.91819 0.86874 8.09273 1.35365 9.00086 2.18692C9.90513 1.35688 11.0738 0.872208 12.301 0.818329C13.0316 0.847562 13.7461 1.04138 14.3912 1.38529C15.0362 1.7292 15.5949 2.2143 16.0257 2.80428C17.7241 4.91668 17.5156 8.58362 15.616 11.5291C14.1868 13.6292 12.3534 15.4244 10.2226 16.8101C9.86024 17.0501 9.43566 17.1793 9.00086 17.182Z"
          fill="#FF6A00"
        />
      </g>
      <defs>
        <clipPath id="clip0_1318_10187">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});
