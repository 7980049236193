import { createIcon } from "@chakra-ui/icons";

export const DeliveryIcon = createIcon({
  displayName: "DeliveryIcon",
  defaultProps: {
    width: "20px",
    height: "20px",
  },
  viewBox: "0 0 20 20",
  path: (
    <>
      <g clipPath="url(#clip0_715_20182)">
        <path
          d="M19.1673 6.39999L17.834 2.54165C17.6611 2.05268 17.3404 1.62957 16.9164 1.33096C16.4924 1.03234 15.9859 0.872989 15.4673 0.874988H4.53398C4.01535 0.872989 3.50894 1.03234 3.0849 1.33096C2.66086 1.62957 2.34019 2.05268 2.16732 2.54165L0.875651 6.39999C0.849674 6.48656 0.835655 6.57628 0.833984 6.66666V16.6667C0.833984 17.3297 1.09738 17.9656 1.56622 18.4344C2.03506 18.9033 2.67094 19.1667 3.33398 19.1667H16.6673C17.3304 19.1667 17.9662 18.9033 18.4351 18.4344C18.9039 17.9656 19.1673 17.3297 19.1673 16.6667V6.66666C19.1793 6.57817 19.1793 6.48847 19.1673 6.39999ZM16.3007 3.06665L17.1757 5.83332H10.834V2.49999H15.4673C15.642 2.49954 15.8124 2.554 15.9545 2.65569C16.0966 2.75738 16.2031 2.90114 16.259 3.06665H16.3007ZM4.53398 2.49999H9.16732V5.83332H2.82565L3.74232 3.06665C3.79822 2.90114 3.90473 2.75738 4.04679 2.65569C4.18885 2.554 4.35928 2.49954 4.53398 2.49999ZM16.6673 17.5H3.33398C3.11297 17.5 2.90101 17.4122 2.74473 17.2559C2.58845 17.0996 2.50065 16.8877 2.50065 16.6667V7.49999H9.16732V9.99999C9.16732 10.221 9.25512 10.433 9.4114 10.5892C9.56768 10.7455 9.77964 10.8333 10.0007 10.8333C10.2217 10.8333 10.4336 10.7455 10.5899 10.5892C10.7462 10.433 10.834 10.221 10.834 9.99999V7.49999H17.5007V16.6667C17.5007 16.8877 17.4129 17.0996 17.2566 17.2559C17.1003 17.4122 16.8883 17.5 16.6673 17.5Z"
          fill="currentColor"
        />
        <path
          d="M8.33464 14.1667H5.0013C4.78029 14.1667 4.56833 14.2545 4.41205 14.4107C4.25577 14.567 4.16797 14.779 4.16797 15C4.16797 15.221 4.25577 15.433 4.41205 15.5892C4.56833 15.7455 4.78029 15.8333 5.0013 15.8333H8.33464C8.55565 15.8333 8.76761 15.7455 8.92389 15.5892C9.08017 15.433 9.16797 15.221 9.16797 15C9.16797 14.779 9.08017 14.567 8.92389 14.4107C8.76761 14.2545 8.55565 14.1667 8.33464 14.1667Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_715_20182">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});
