import { createIcon } from "@chakra-ui/icons";

export const NotificationsIcon = createIcon({
  displayName: "NotificationsIcon",
  defaultProps: {
    width: "20px",
    height: "20px",
  },
  viewBox: "0 0 20 20",
  path: (
    <path
      d="M17.3971 11.6961C16.534 10.3723 16.0032 8.85092 15.8516 7.26679L15.745 6.22994C15.5734 4.52132 14.7892 2.93854 13.5438 1.78777C12.2985 0.636993 10.6807 0 9.00319 0C7.32573 0 5.70787 0.636993 4.46255 1.78777C3.21723 2.93854 2.43295 4.52132 2.26141 6.22994L2.15482 7.26679C2.0018 8.84777 1.471 10.3659 0.609275 11.687C0.312526 12.1394 0.141659 12.666 0.115007 13.2102C0.0883543 13.7545 0.206922 14.2959 0.457989 14.7765C0.709056 15.257 1.08315 15.6584 1.54013 15.9377C1.99711 16.217 2.51974 16.3637 3.05195 16.3619H5.45021C5.45021 17.3268 5.82454 18.2522 6.49086 18.9344C7.15717 19.6167 8.06088 20 9.00319 20C9.9455 20 10.8492 19.6167 11.5155 18.9344C12.1818 18.2522 12.5562 17.3268 12.5562 16.3619H14.9544C15.4852 16.3626 16.0062 16.2156 16.4618 15.9368C16.9174 15.6579 17.2905 15.2576 17.5413 14.7786C17.7921 14.2996 17.9111 13.7598 17.8857 13.2169C17.8603 12.674 17.6915 12.1484 17.3971 11.6961ZM9.00319 18.181C8.53204 18.181 8.08018 17.9893 7.74703 17.6482C7.41387 17.3071 7.2267 16.8444 7.2267 16.3619H10.7797C10.7797 16.8444 10.5925 17.3071 10.2594 17.6482C9.9262 17.9893 9.47435 18.181 9.00319 18.181ZM14.9544 14.5429H3.05195C2.90031 14.5429 2.75015 14.5123 2.61006 14.4529C2.46996 14.3935 2.34267 14.3064 2.23544 14.1966C2.12821 14.0868 2.04316 13.9565 1.98513 13.813C1.9271 13.6696 1.89723 13.5158 1.89723 13.3605C1.90417 13.134 1.97177 12.9138 2.09264 12.7239C3.11539 11.1489 3.74381 9.34031 3.92243 7.45779L4.02902 6.42094C4.15655 5.16103 4.73561 3.99424 5.65432 3.146C6.57302 2.29776 7.76615 1.82828 9.00319 1.82828C10.2402 1.82828 11.4334 2.29776 12.3521 3.146C13.2708 3.99424 13.8498 5.16103 13.9774 6.42094L14.084 7.45779C14.2626 9.34031 14.891 11.1489 15.9137 12.7239C16.0394 12.9216 16.1072 13.152 16.1092 13.3878C16.1022 13.6967 15.9775 13.9904 15.7617 14.2063C15.5459 14.4222 15.2561 14.543 14.9544 14.5429Z"
      fill="currentColor"
    />
  ),
});
