import { createIcon } from "@chakra-ui/icons";

export const InfoIcon = createIcon({
  displayName: "InfoIcon",
  defaultProps: {
    width: "14px",
    height: "14px",
  },
  viewBox: "0 0 14 14",
  path: (
    <>
      <g clipPath="url(#clip0_1360_1523)">
        <path
          d="M6.99935 12.8334C10.221 12.8334 12.8327 10.2217 12.8327 7.00002C12.8327 3.77836 10.221 1.16669 6.99935 1.16669C3.77769 1.16669 1.16602 3.77836 1.16602 7.00002C1.16602 10.2217 3.77769 12.8334 6.99935 12.8334Z"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7 9.33333V7"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7 4.66669H7.00583"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1360_1523">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});
