import { createIcon } from "@chakra-ui/icons";

export const AttentionIcon = createIcon({
  displayName: "AttentionIcon",
  defaultProps: {
    width: "14px",
    height: "15px",
  },
  viewBox: "0 0 14 15",
  path: (
    <>
      <path
        d="M7.00008 13.3332C10.2217 13.3332 12.8334 10.7215 12.8334 7.49984C12.8334 4.27818 10.2217 1.6665 7.00008 1.6665C3.77842 1.6665 1.16675 4.27818 1.16675 7.49984C1.16675 10.7215 3.77842 13.3332 7.00008 13.3332Z"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 5.1665V7.49984"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 9.8335H7.00648"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});
