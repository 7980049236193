import { createIcon } from "@chakra-ui/icons";

export const PasswordIcon = createIcon({
  displayName: "PasswordIcon",
  defaultProps: {
    width: "18px",
    height: "18px",
  },
  viewBox: "0 0 18 18",
  path: (
    <path
      d="M15.7497 1.5L14.2497 3M14.2497 3L16.4997 5.25L13.8747 7.875L11.6247 5.625M14.2497 3L11.6247 5.625M8.54222 8.7075C8.92947 9.0896 9.23732 9.54453 9.44803 10.0461C9.65875 10.5477 9.76817 11.0859 9.76999 11.63C9.77181 12.174 9.666 12.713 9.45865 13.216C9.25131 13.7189 8.94651 14.1759 8.56183 14.5606C8.17714 14.9453 7.72016 15.2501 7.2172 15.4574C6.71423 15.6648 6.17522 15.7706 5.63119 15.7688C5.08717 15.7669 4.54888 15.6575 4.04731 15.4468C3.54575 15.2361 3.09082 14.9283 2.70872 14.541C1.95732 13.763 1.54154 12.721 1.55094 11.6395C1.56034 10.5579 1.99416 9.52331 2.75897 8.7585C3.52377 7.99369 4.55838 7.55987 5.63994 7.55047C6.7215 7.54107 7.76348 7.95685 8.54147 8.70825L8.54222 8.7075ZM8.54222 8.7075L11.6247 5.625"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});
