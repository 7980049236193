import { createIcon } from "@chakra-ui/icons";

export const TengeIcon = createIcon({
  displayName: "TengeIcon",
  defaultProps: {
    width: "18px",
    height: "18px",
  },
  viewBox: "0 0 18 18",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.85717 11.5718V13.2853H9.85723V14.9996H8.14294V13.2861H8.14287V11.5718V9.85752H9.85717V11.5718ZM6.42865 6.42816H8.14287V6.42898V8.14327H8.14294V9.85675H9.85723V8.14246H9.85717V6.42898V4.71468H8.14294V4.71387H6.42865H4.71436V6.42816H6.42865ZM6.42858 1.28613H8.14287H9.85717V3.00043H8.14287H6.42858H4.71429H3V1.28613H4.71429H6.42858ZM3 4.71468H4.71429V6.42898H3V4.71468ZM13.2859 4.71468L15 4.71468V6.42898L13.2859 6.42898L13.2857 6.42898L11.5716 6.42898H9.85732V4.71468H11.5716L13.2857 4.71468L13.2859 4.71468ZM13.2859 1.28613H15V3.00043L13.2859 3.00043L13.2857 3.00043L11.5716 3.00043H9.85732V1.28613H11.5716H13.2857H13.2859ZM9.85736 15.0004H8.14307V16.7147H9.85736V15.0004Z"
      fill="currentColor"
    />
  ),
});
