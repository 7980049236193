import { createIcon } from "@chakra-ui/icons";

export const FAQIcon = createIcon({
  displayName: "FAQIcon",
  defaultProps: {
    width: "18px",
    height: "18px",
  },
  viewBox: "0 0 18 18",
  path: (
    <>
      <g clipPath="url(#clip0_1607_13011)">
        <path
          d="M14.8367 3.16499C13.4407 1.77129 11.5916 0.923897 9.6245 0.776448C7.65741 0.628999 5.7026 1.19125 4.11445 2.36128C2.5263 3.5313 1.40995 5.23164 0.967768 7.15405C0.525589 9.07646 0.786859 11.0937 1.70421 12.84L1.11171 15.0525C1.04404 15.3068 1.04446 15.5744 1.11291 15.8285C1.18137 16.0825 1.31545 16.3141 1.50171 16.5C1.68758 16.6863 1.91917 16.8203 2.17325 16.8888C2.42732 16.9572 2.69493 16.9577 2.94921 16.89L5.16171 16.2975C6.34431 16.9249 7.66299 17.252 9.00171 17.25C9.41833 17.2499 9.8344 17.2198 10.2467 17.16C11.7559 16.9308 13.172 16.2873 14.3373 15.3012C15.5026 14.3151 16.3714 13.0251 16.8471 11.5746C17.3229 10.1241 17.3868 8.57 17.0319 7.0853C16.6769 5.60061 15.917 4.24351 14.8367 3.16499ZM15.7517 9.615C15.6507 10.7433 15.2674 11.828 14.6371 12.7692C14.0067 13.7104 13.1497 14.4779 12.1448 15.0008C11.14 15.5238 10.0197 15.7855 8.88715 15.7617C7.75461 15.738 6.64626 15.4296 5.66421 14.865C5.54991 14.8002 5.42059 14.7666 5.28921 14.7675C5.22442 14.7601 5.159 14.7601 5.09421 14.7675L2.58921 15.4425L3.26421 12.9375C3.28981 12.8422 3.29636 12.7428 3.2835 12.645C3.27063 12.5472 3.23859 12.4529 3.18921 12.3675C2.65703 11.4481 2.34936 10.4161 2.29121 9.35542C2.23306 8.29472 2.42608 7.23527 2.85457 6.26323C3.28306 5.29119 3.93492 4.43401 4.75714 3.76139C5.57936 3.08877 6.54873 2.6197 7.5864 2.39234C8.62408 2.16498 9.70077 2.18575 10.7289 2.45294C11.757 2.72014 12.7076 3.22623 13.5033 3.93005C14.299 4.63387 14.9173 5.51555 15.308 6.50338C15.6987 7.49122 15.8507 8.55733 15.7517 9.615Z"
          fill="currentColor"
        />
        <path
          d="M9 14.25C9.62132 14.25 10.125 13.7463 10.125 13.125C10.125 12.5037 9.62132 12 9 12C8.37868 12 7.875 12.5037 7.875 13.125C7.875 13.7463 8.37868 14.25 9 14.25Z"
          fill="currentColor"
        />
        <path
          d="M9.51 3.75H8.49C7.82961 3.75 7.19627 4.01234 6.7293 4.4793C6.26234 4.94627 6 5.57961 6 6.24V6.75C6 6.94891 6.07902 7.13968 6.21967 7.28033C6.36032 7.42098 6.55109 7.5 6.75 7.5C6.94891 7.5 7.13968 7.42098 7.28033 7.28033C7.42098 7.13968 7.5 6.94891 7.5 6.75V6.24C7.5 5.97744 7.6043 5.72563 7.78996 5.53996C7.97562 5.3543 8.22744 5.25 8.49 5.25H9.51C9.74242 5.2558 9.96552 5.3426 10.1407 5.49541C10.316 5.64822 10.4323 5.85744 10.4697 6.08692C10.507 6.31639 10.463 6.55171 10.3453 6.7522C10.2276 6.9527 10.0436 7.10578 9.825 7.185C9.36611 7.33662 8.9668 7.62924 8.68398 8.02113C8.40117 8.41303 8.24929 8.88421 8.25 9.3675V9.75C8.25 9.94891 8.32902 10.1397 8.46967 10.2803C8.61032 10.421 8.80109 10.5 9 10.5C9.19891 10.5 9.38968 10.421 9.53033 10.2803C9.67098 10.1397 9.75 9.94891 9.75 9.75V9.3675C9.75001 9.2006 9.80304 9.03802 9.90145 8.90322C9.99985 8.76842 10.1385 8.66836 10.2975 8.6175C10.8604 8.43039 11.3383 8.04903 11.6457 7.5417C11.9531 7.03437 12.0699 6.4342 11.9752 5.84862C11.8804 5.26305 11.5803 4.73031 11.1287 4.34579C10.677 3.96127 10.1032 3.75007 9.51 3.75Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1607_13011">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});
