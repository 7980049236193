import { createIcon } from "@chakra-ui/icons";

export const PlusCycle = createIcon({
  displayName: "PlusCycle",
  defaultProps: {
    width: "18px",
    height: "18px",
  },
  viewBox: "0 0 18 18",
  path: (
    <>
      <g clipPath="url(#clip0_638_13122)">
        <path
          d="M17.25 9C17.25 13.5563 13.5563 17.25 9 17.25C4.44365 17.25 0.75 13.5563 0.75 9C0.75 4.44365 4.44365 0.75 9 0.75C13.5563 0.75 17.25 4.44365 17.25 9Z"
          stroke="currentColor"
          fill="none"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 5L9 13"
          stroke="currentColor"
          fill="none"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 9L13 9"
          stroke="currentColor"
          fill="none"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_638_13122">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});
