import { createIcon } from "@chakra-ui/icons";

export const ChevronLeftIcon = createIcon({
  displayName: "ChevronLeftIcon",
  defaultProps: {
    width: "24px",
    height: "24px",
  },
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M15 18L9 12L15 6"
      stroke="currentColor"
      strokeWidth="1.5"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});
