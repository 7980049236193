import { createIcon } from "@chakra-ui/icons";

export const ClockIcon = createIcon({
  displayName: "ClockIcon",
  defaultProps: {
    width: "18px",
    height: "18px",
  },
  viewBox: "0 0 18 18",
  path: (
    <>
      <g id="clock" clipPath="url(#clip0_479_25474)">
        <path
          id="Vector"
          d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
          stroke="currentColor"
          fill="none"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M9 4.5V9L12 10.5"
          stroke="currentColor"
          fill="none"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_479_25474">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});
