import { createIcon } from "@chakra-ui/icons";

export const URLIcon = createIcon({
  displayName: "URLIcon",
  defaultProps: {
    width: "18px",
    height: "18px",
  },
  viewBox: "0 0 18 18",
  path: (
    <>
      <g clipPath="url(#clip0_715_3001)">
        <path
          d="M7.5 9.74997C7.82209 10.1806 8.23302 10.5369 8.70491 10.7947C9.17681 11.0525 9.69863 11.2058 10.235 11.2442C10.7713 11.2826 11.3097 11.2052 11.8135 11.0173C12.3173 10.8294 12.7748 10.5353 13.155 10.155L15.405 7.90497C16.0881 7.19772 16.4661 6.25046 16.4575 5.26722C16.449 4.28398 16.0546 3.34343 15.3593 2.64815C14.664 1.95287 13.7235 1.55849 12.7403 1.54995C11.757 1.5414 10.8098 1.91938 10.1025 2.60247L8.8125 3.88497"
          stroke="currentColor"
          fill="none"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.4997 8.24999C10.1776 7.81939 9.76664 7.4631 9.29475 7.20528C8.82285 6.94746 8.30103 6.79415 7.76467 6.75574C7.22832 6.71732 6.68997 6.79471 6.18615 6.98265C5.68233 7.17059 5.22483 7.46468 4.84466 7.84499L2.59466 10.095C1.91157 10.8022 1.53359 11.7495 1.54213 12.7327C1.55068 13.716 1.94506 14.6565 2.64034 15.3518C3.33562 16.0471 4.27617 16.4415 5.25941 16.45C6.24264 16.4586 7.1899 16.0806 7.89716 15.3975L9.17966 14.115"
          stroke="currentColor"
          fill="none"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_715_3001">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});
