import { createIcon } from "@chakra-ui/icons";

export const AlertCycleIcon = createIcon({
  displayName: "AlertCycleIcon",
  defaultProps: {
    width: "14px",
    height: "15px",
  },
  viewBox: "0 0 14 15",
  path: (
    <>
      <path
        d="M6.99935 13.3334C10.221 13.3334 12.8327 10.7217 12.8327 7.50008C12.8327 4.27842 10.221 1.66675 6.99935 1.66675C3.77769 1.66675 1.16602 4.27842 1.16602 7.50008C1.16602 10.7217 3.77769 13.3334 6.99935 13.3334Z"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 5.16675V7.50008"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 9.83325H7.00667"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});
