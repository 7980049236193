import { useEffect } from "react";

function getUTMParams() {
  const params = new URLSearchParams(window.location.search);
  return {
    utmSource: params.get('utm_source'),
    utmMedium: params.get('utm_medium'),
    utmCampaign: params.get('utm_campaign'),
    utmTerm: params.get('utm_term'),
    utmContent: params.get('utm_content')
  };
}

function saveUTMToLocalStorage(utmParams: {
  utmSource: string | null;
  utmMedium: string | null;
  utmCampaign: string | null;
  utmTerm: string | null;
  utmContent: string | null;
}) {
  const { utmSource, utmMedium, utmCampaign, utmTerm, utmContent } = utmParams;

  if (utmSource) localStorage.setItem('utm_source', utmSource);
  if (utmMedium) localStorage.setItem('utm_medium', utmMedium);
  if (utmCampaign) localStorage.setItem('utm_campaign', utmCampaign);
  if (utmTerm) localStorage.setItem('utm_term', utmTerm);
  if (utmContent) localStorage.setItem('utm_content', utmContent);
}

export default function RouteProvider() {
  useEffect(() => {
    const utmParams = getUTMParams();
    saveUTMToLocalStorage(utmParams);
  }, []);

  return null;
}
