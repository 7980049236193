import { createIcon } from "@chakra-ui/icons";

export const HamburgerIcon = createIcon({
  displayName: "HamburgerIcon",
  defaultProps: {
    width: "30px",
    height: "30px",
  },
  viewBox: "0 0 30 30",
  path: (
    <path
      d="M2.5 22.5H27.5M2.5 15H27.5M2.5 7.5H27.5"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});
