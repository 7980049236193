import { createIcon } from "@chakra-ui/icons";

export const ChatIcon = createIcon({
  displayName: "ChatIcon",
  defaultProps: {
    width: "22px",
    height: "19px",
  },
  viewBox: "0 0 24 24",
  path: (
    <>
      <path
        d="M21 4C20.7348 4 20.4804 4.10536 20.2929 4.29289C20.1054 4.48043 20 4.73478 20 5V13C20 13.7956 19.6839 14.5587 19.1213 15.1213C18.5587 15.6839 17.7956 16 17 16H13C12.7348 16 12.4804 16.1054 12.2929 16.2929C12.1054 16.4804 12 16.7348 12 17C12 17.2652 12.1054 17.5196 12.2929 17.7071C12.4804 17.8946 12.7348 18 13 18H17C17.6566 18 18.3068 17.8707 18.9134 17.6194C19.52 17.3681 20.0712 16.9998 20.5355 16.5355C20.9998 16.0712 21.3681 15.52 21.6194 14.9134C21.8707 14.3068 22 13.6566 22 13V5C22 4.73478 21.8946 4.48043 21.7071 4.29289C21.5196 4.10536 21.2652 4 21 4Z"
        fill="currentColor"
      />
      <path
        d="M18 11V3C18 2.20435 17.6839 1.44129 17.1213 0.87868C16.5587 0.316071 15.7956 0 15 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.31607 1.44129 0 2.20435 0 3V11C0 11.7956 0.31607 12.5587 0.87868 13.1213C1.44129 13.6839 2.20435 14 3 14H4V16C3.99968 16.3964 4.11717 16.7839 4.33753 17.1134C4.5579 17.4429 4.87121 17.6995 5.23768 17.8506C5.60416 18.0017 6.00726 18.0405 6.39582 17.962C6.78438 17.8836 7.14086 17.6914 7.42 17.41L10.83 14H15C15.7956 14 16.5587 13.6839 17.1213 13.1213C17.6839 12.5587 18 11.7956 18 11ZM10.41 12C10.1477 12.0011 9.89626 12.1053 9.71 12.29L6 16V13C6 12.7348 5.89464 12.4804 5.70711 12.2929C5.51957 12.1054 5.26522 12 5 12H3C2.73478 12 2.48043 11.8946 2.29289 11.7071C2.10536 11.5196 2 11.2652 2 11V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H15C15.2652 2 15.5196 2.10536 15.7071 2.29289C15.8946 2.48043 16 2.73478 16 3V11C16 11.2652 15.8946 11.5196 15.7071 11.7071C15.5196 11.8946 15.2652 12 15 12H10.41Z"
        fill="currentColor"
      />
    </>
  ),
});
