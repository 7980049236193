import { createIcon } from "@chakra-ui/icons";

export const HomeIcon = createIcon({
  displayName: "HomeIcon",
  defaultProps: {
    width: "23px",
    height: "22px",
  },
  viewBox: "0 0 23 22",
  path: (
    <>
      <path
        d="M3.25 8.24992L11.5 1.83325L19.75 8.24992V18.3333C19.75 18.8195 19.5568 19.2858 19.213 19.6296C18.8692 19.9734 18.4029 20.1666 17.9167 20.1666H5.08333C4.5971 20.1666 4.13079 19.9734 3.78697 19.6296C3.44315 19.2858 3.25 18.8195 3.25 18.3333V8.24992Z"
        stroke="currentColor"
        strokeWidth="2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});
