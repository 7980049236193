import { createIcon } from "@chakra-ui/icons";

export const CameraIcon = createIcon({
  displayName: "CameraIcon",
  defaultProps: {
    width: "19px",
    height: "18px",
  },
  viewBox: "0 0 19 18",
  path: (
    <>
      <g clipPath="url(#clip0_255_11669)">
        <path
          d="M17.75 14.25C17.75 14.6478 17.592 15.0294 17.3107 15.3107C17.0294 15.592 16.6478 15.75 16.25 15.75H2.75C2.35218 15.75 1.97064 15.592 1.68934 15.3107C1.40804 15.0294 1.25 14.6478 1.25 14.25V6C1.25 5.60218 1.40804 5.22064 1.68934 4.93934C1.97064 4.65804 2.35218 4.5 2.75 4.5H5.75L7.25 2.25H11.75L13.25 4.5H16.25C16.6478 4.5 17.0294 4.65804 17.3107 4.93934C17.592 5.22064 17.75 5.60218 17.75 6V14.25Z"
          stroke="currentColor"
          fill="none"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path
          d="M9.5 12.75C11.1569 12.75 12.5 11.4069 12.5 9.75C12.5 8.09315 11.1569 6.75 9.5 6.75C7.84315 6.75 6.5 8.09315 6.5 9.75C6.5 11.4069 7.84315 12.75 9.5 12.75Z"
          stroke="currentColor"
          fill="none"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_255_11669">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </>
  ),
});
