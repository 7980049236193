import { createIcon } from "@chakra-ui/icons";

export const ExcelIcon = createIcon({
  displayName: "ExcelIcon",
  defaultProps: {
    width: "14px",
    height: "15px",
  },
  viewBox: "0 0 18 18",
  path: (
    <>
      <g clipPath="url(#clip0_844_9545)">
        <g clipPath="url(#clip1_844_9545)">
          <path
            d="M3.33594 2.85156V1.73047C3.33594 1.27741 3.70319 0.910156 4.15625 0.910156H12.7695C13.2226 0.910156 13.5898 1.27741 13.5898 1.73047V10.5483C13.5898 10.6675 13.5641 10.7843 13.5155 10.8906C13.4748 10.9809 13.4181 11.0631 13.3481 11.1332L10.6474 13.8457C10.5763 13.917 10.494 13.9739 10.4043 14.0146C10.2971 14.0639 10.1798 14.0898 10.0598 14.0898H4.16063C3.70508 14.0898 3.33594 13.7207 3.33594 13.2652V9.27734"
            stroke="currentColor"
            fill="none"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.5155 10.8905H10.4043V14.0145M0.410156 2.85156H11.6876V9.24981H0.410156V2.85156Z"
            stroke="currentColor"
            fill="none"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.56102 4.49219V7.60656C5.59548 7.61246 6.6525 7.60656 6.6525 7.60656M9.93225 4.81263C9.93225 4.81263 9.37293 4.34248 8.71338 4.54116C8.10777 4.7236 8.02305 5.4219 8.46118 5.70756C8.46118 5.70756 8.89111 5.89938 9.36798 6.07525C10.5159 6.49867 10.0214 7.60918 9.09734 7.60918C8.63457 7.60918 8.24621 7.40654 8.01113 7.14713M2.07423 4.49219L3.9204 7.60918M3.90459 4.49219L2.05566 7.60918"
            stroke="currentColor"
            fill="none"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_844_9545">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
        <clipPath id="clip1_844_9545">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </>
  ),
});
