import { createIcon } from "@chakra-ui/icons";

const SliderBackIcon = createIcon({
  displayName: "SliderBackIcon",
  defaultProps: {
    width: "60px",
    height: "60px",
  },
  viewBox: "0 0 60 60",
  path: (
    <g id="Button back">
      <path
        id="Vector"
        d="M29.25 54C42.919 54 54 42.919 54 29.25C54 15.581 42.919 4.5 29.25 4.5C15.581 4.5 4.5 15.581 4.5 29.25C4.5 42.919 15.581 54 29.25 54Z"
        fill="#F2F3F6"
      />
      <path
        id="Vector_2"
        d="M31.5 19.5L19.5 30L31.5 40.5"
        stroke="#797E8E"
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M39 30H19.5"
        stroke="#797E8E"
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});

export default SliderBackIcon;
