import { createIcon } from "@chakra-ui/icons";

export const QuestionIcon = createIcon({
  displayName: "QuestionIcon",
  defaultProps: {
    width: "14px",
    height: "14px",
  },
  viewBox: "0 0 14 14",
  path: (
    <>
      <g clipPath="url(#clip0_275_11562)">
        <path
          d="M7.00033 12.8333C10.222 12.8333 12.8337 10.2216 12.8337 6.99996C12.8337 3.7783 10.222 1.16663 7.00033 1.16663C3.77866 1.16663 1.16699 3.7783 1.16699 6.99996C1.16699 10.2216 3.77866 12.8333 7.00033 12.8333Z"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.30273 5.24997C5.43988 4.86011 5.71057 4.53136 6.06687 4.32196C6.42318 4.11256 6.84209 4.03601 7.24942 4.10588C7.65675 4.17575 8.02621 4.38752 8.29236 4.70369C8.55851 5.01986 8.70418 5.42002 8.70357 5.8333C8.70357 6.99997 6.95357 7.5833 6.95357 7.5833"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7 9.91663H7.00648"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_275_11562">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});
