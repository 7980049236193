import { createIcon } from "@chakra-ui/icons";

export const PlayCircleIcon = createIcon({
  displayName: "PlayCircleIcon",
  defaultProps: {
    width: "70px",
    height: "70px",
  },
  viewBox: "0 0 70 70",
  path: (
    <>
      <path
        d="M34.9997 64.1667C51.108 64.1667 64.1663 51.1083 64.1663 35C64.1663 18.8917 51.108 5.83331 34.9997 5.83331C18.8914 5.83331 5.83301 18.8917 5.83301 35C5.83301 51.1083 18.8914 64.1667 34.9997 64.1667Z"
        fill="white"
      />
      <path
        d="M29.167 23.3333L46.667 35L29.167 46.6666V23.3333Z"
        fill="currentColor"
      />
    </>
  ),
});
