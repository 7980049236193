import { createIcon } from "@chakra-ui/icons";

export const VideoIcon = createIcon({
  displayName: "VideoIcon",
  defaultProps: {
    width: "18px",
    height: "18px",
  },
  viewBox: "0 0 18 18",
  path: (
    <>
      <path
        d="M16.6275 4.785C16.4352 4.64561 16.2125 4.55389 15.9778 4.51738C15.7431 4.48087 15.5031 4.50062 15.2775 4.575L13.83 5.0625C13.519 4.4452 13.0433 3.92589 12.4557 3.56192C11.868 3.19795 11.1912 3.00348 10.5 3H4.5C4.00754 3 3.51991 3.097 3.06494 3.28545C2.60997 3.47391 2.19657 3.75013 1.84835 4.09835C1.14509 4.80161 0.75 5.75544 0.75 6.75V11.25C0.75 12.2446 1.14509 13.1984 1.84835 13.9017C2.19657 14.2499 2.60997 14.5261 3.06494 14.7145C3.51991 14.903 4.00754 15 4.5 15H10.5C11.1912 14.9965 11.868 14.8021 12.4557 14.4381C13.0433 14.0741 13.519 13.5548 13.83 12.9375L15.2775 13.425C15.4298 13.476 15.5894 13.5014 15.75 13.5C16.0652 13.4995 16.3722 13.3998 16.6275 13.215C16.82 13.0762 16.9768 12.8936 17.085 12.6824C17.1932 12.4712 17.2498 12.2373 17.25 12V6C17.2498 5.76267 17.1932 5.52879 17.085 5.31758C16.9768 5.10636 16.82 4.92384 16.6275 4.785ZM12.75 11.25C12.75 11.8467 12.5129 12.419 12.091 12.841C11.669 13.2629 11.0967 13.5 10.5 13.5H4.5C3.90326 13.5 3.33097 13.2629 2.90901 12.841C2.48705 12.419 2.25 11.8467 2.25 11.25V6.75C2.25 6.15326 2.48705 5.58097 2.90901 5.15901C3.33097 4.73705 3.90326 4.5 4.5 4.5H10.5C11.0967 4.5 11.669 4.73705 12.091 5.15901C12.5129 5.58097 12.75 6.15326 12.75 6.75V11.25ZM14.25 11.49V6.51L15.75 6V12L14.25 11.49Z"
        fill="currentColor"
      />
      <path
        d="M9.375 7.7025L7.125 6.405C6.89708 6.27342 6.63856 6.20411 6.37538 6.20404C6.11221 6.20397 5.85365 6.27315 5.62567 6.40462C5.39768 6.53609 5.2083 6.72523 5.07654 6.95304C4.94478 7.18086 4.87527 7.43933 4.875 7.7025V10.2975C4.87527 10.5605 4.94471 10.8189 5.07635 11.0466C5.20799 11.2744 5.3972 11.4635 5.625 11.595C5.85096 11.7326 6.11046 11.8052 6.375 11.805C6.63913 11.8023 6.89785 11.7298 7.125 11.595L9.375 10.2975C9.60231 10.1656 9.79099 9.97639 9.92215 9.74867C10.0533 9.52096 10.1223 9.26279 10.1223 9C10.1223 8.73722 10.0533 8.47905 9.92215 8.25133C9.79099 8.02362 9.60231 7.83436 9.375 7.7025ZM6.375 10.2975V7.7025L8.625 9L6.375 10.2975Z"
        fill="currentColor"
      />
    </>
  ),
});
