import { Suspense } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { routes } from '@/router/routes';

export default function RouteProvider() {
  const router = createBrowserRouter(routes);

  return (
    <Suspense fallback={<Box />}>
      <RouterProvider router={router} fallbackElement={<Box />} />
    </Suspense>
  );
}
