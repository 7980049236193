import { createIcon } from "@chakra-ui/icons";

export const HelpIcon = createIcon({
  displayName: "HelpIcon",
  defaultProps: {
    width: "20px",
    height: "20px",
  },
  viewBox: "0 0 20 20",
  path: (
    <>
      <path
        d="M16.4831 3.51669C14.932 1.96813 12.8774 1.02659 10.6917 0.862754C8.50606 0.698922 6.33405 1.32365 4.56944 2.62367C2.80483 3.9237 1.56444 5.81297 1.07313 7.94898C0.581818 10.085 0.872117 12.3263 1.8914 14.2667L1.23306 16.725C1.15788 17.0076 1.15834 17.3049 1.2344 17.5872C1.31046 17.8695 1.45944 18.1268 1.6664 18.3334C1.87292 18.5403 2.13024 18.6893 2.41255 18.7654C2.69485 18.8414 2.99219 18.8419 3.27473 18.7667L5.73306 18.1084C7.04706 18.8055 8.51227 19.1689 9.99973 19.1667C10.4626 19.1666 10.9249 19.1331 11.3831 19.0667C13.06 18.812 14.6334 18.0971 15.9281 17.0014C17.2229 15.9057 18.1883 14.4723 18.7169 12.8607C19.2454 11.249 19.3165 9.52225 18.9221 7.87259C18.5278 6.22293 17.6834 4.71504 16.4831 3.51669ZM17.4997 10.6834C17.3875 11.937 16.9616 13.1422 16.2612 14.188C15.5609 15.2338 14.6086 16.0865 13.4921 16.6676C12.3756 17.2487 11.1308 17.5394 9.87244 17.5131C8.61406 17.4867 7.38256 17.144 6.2914 16.5167C6.16439 16.4447 6.02071 16.4074 5.87473 16.4084C5.80274 16.4002 5.73005 16.4002 5.65806 16.4084L2.87473 17.1584L3.62473 14.375C3.65318 14.2692 3.66046 14.1587 3.64616 14.0501C3.63186 13.9414 3.59626 13.8366 3.5414 13.7417C2.95009 12.7202 2.60823 11.5735 2.54362 10.3949C2.47901 9.21639 2.69347 8.03922 3.16957 6.95918C3.64567 5.87913 4.36996 4.92671 5.28354 4.17935C6.19712 3.432 7.27419 2.91082 8.42717 2.65819C9.58014 2.40557 10.7765 2.42864 11.9188 2.72553C13.0612 3.02241 14.1174 3.58473 15.0015 4.36675C15.8856 5.14878 16.5726 6.12842 17.0067 7.22602C17.4408 8.32361 17.6097 9.50817 17.4997 10.6834Z"
        fill="currentColor"
      />
      <path
        d="M10 15.8334C10.6904 15.8334 11.25 15.2737 11.25 14.5834C11.25 13.893 10.6904 13.3334 10 13.3334C9.30964 13.3334 8.75 13.893 8.75 14.5834C8.75 15.2737 9.30964 15.8334 10 15.8334Z"
        fill="currentColor"
      />
      <path
        d="M10.566 4.16669H9.43268C8.69892 4.16669 7.9952 4.45817 7.47635 4.97702C6.9575 5.49588 6.66602 6.19959 6.66602 6.93335V7.50002C6.66602 7.72103 6.75381 7.933 6.91009 8.08928C7.06637 8.24556 7.27834 8.33335 7.49935 8.33335C7.72036 8.33335 7.93232 8.24556 8.0886 8.08928C8.24488 7.933 8.33268 7.72103 8.33268 7.50002V6.93335C8.33268 6.64162 8.44857 6.36183 8.65486 6.15554C8.86115 5.94925 9.14094 5.83335 9.43268 5.83335H10.566C10.8243 5.8398 11.0721 5.93625 11.2668 6.10604C11.4615 6.27582 11.5908 6.50829 11.6323 6.76326C11.6738 7.01823 11.625 7.27969 11.4942 7.50247C11.3634 7.72524 11.1589 7.89533 10.916 7.98335C10.4061 8.15182 9.96246 8.47695 9.64822 8.91239C9.33398 9.34784 9.16523 9.87137 9.16602 10.4084V10.8334C9.16602 11.0544 9.25381 11.2663 9.41009 11.4226C9.56637 11.5789 9.77834 11.6667 9.99935 11.6667C10.2204 11.6667 10.4323 11.5789 10.5886 11.4226C10.7449 11.2663 10.8327 11.0544 10.8327 10.8334V10.4084C10.8327 10.2229 10.8916 10.0423 11.001 9.89249C11.1103 9.74271 11.2644 9.63154 11.441 9.57502C12.0665 9.36712 12.5975 8.94339 12.939 8.37969C13.2806 7.81599 13.4103 7.14913 13.3051 6.49849C13.1998 5.84785 12.8664 5.25592 12.3645 4.82868C11.8627 4.40143 11.2251 4.16676 10.566 4.16669Z"
        fill="currentColor"
      />
    </>
  ),
});
