import { useState, useMemo, ReactNode } from "react";
import { authContext } from "@/contexts/authContext";

interface AuthProviderProps {
  children: ReactNode;
}

export default function AuthProvider({ children }: AuthProviderProps) {
  const token = localStorage.getItem("token");
  const [isAuth, setIsAuth] = useState<boolean>(!!token);

  const value = useMemo(() => ({ isAuth, setIsAuth }), [isAuth]);

  return <authContext.Provider value={value}>{children}</authContext.Provider>;
}
