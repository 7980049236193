import { createIcon } from "@chakra-ui/icons";

export const DocumentIcon = createIcon({
  displayName: "DocumentIcon",
  defaultProps: {
    width: "22px",
    height: "22px",
  },
  viewBox: "0 0 22 22",
  path: (
    <g id="file-text">
      <path
        id="Vector"
        d="M12.8332 1.83331H5.49984C5.01361 1.83331 4.54729 2.02647 4.20347 2.37028C3.85966 2.7141 3.6665 3.18042 3.6665 3.66665V18.3333C3.6665 18.8195 3.85966 19.2859 4.20347 19.6297C4.54729 19.9735 5.01361 20.1666 5.49984 20.1666H16.4998C16.9861 20.1666 17.4524 19.9735 17.7962 19.6297C18.14 19.2859 18.3332 18.8195 18.3332 18.3333V7.33331L12.8332 1.83331Z"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M12.8335 1.83331V7.33331H18.3335"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M14.6668 11.9167H7.3335"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_4"
        d="M14.6668 15.5833H7.3335"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_5"
        d="M9.16683 8.25H8.25016H7.3335"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});
