import {
  createContext,
  useContext,
} from "react"; /* eslint-disable react-refresh/only-export-components */

interface AuthContextType {
  isAuth: boolean;
  setIsAuth: (value: boolean) => void;
}

const authContext = createContext<AuthContextType>({
  isAuth: false,
  setIsAuth: () => null,
});

authContext.displayName = "authContext";

const AuthContextConsumer = authContext.Consumer;

export { AuthContextConsumer as AuthConsumer, authContext, useAuthContext };

function useAuthContext(): AuthContextType {
  return useContext(authContext);
}
