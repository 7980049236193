import { createIcon } from "@chakra-ui/icons";

export const MinusIcon = createIcon({
  displayName: "MinusIcon",
  defaultProps: {
    width: "14px",
    height: "14px",
  },
  viewBox: "0 0 14 14",
  path: (
    <path
      d="M2.91669 7H11.0834"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});
