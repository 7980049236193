import { createIcon } from "@chakra-ui/icons";

export const TelegramIcon = createIcon({
  displayName: "TelegramIcon",
  defaultProps: {
    width: "25px",
    height: "24px",
  },
  viewBox: "0 0 25 24",
  path: (
    <>
      <g id="Telegram" clipPath="url(#clip0_2245_22751)">
        <g id="Group">
          <path
            id="Vector"
            d="M12.5 24C19.129 24 24.5 18.629 24.5 12C24.5 5.371 19.129 0 12.5 0C5.871 0 0.5 5.371 0.5 12C0.5 18.629 5.871 24 12.5 24ZM5.991 11.74L17.561 7.279C18.098 7.085 18.567 7.41 18.393 8.222L18.394 8.221L16.424 17.502C16.278 18.16 15.887 18.32 15.34 18.01L12.34 15.799L10.893 17.193C10.733 17.353 10.598 17.488 10.288 17.488L10.501 14.435L16.061 9.412C16.303 9.199 16.007 9.079 15.688 9.291L8.817 13.617L5.855 12.693C5.212 12.489 5.198 12.05 5.991 11.74Z"
            fill="currentColor"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2245_22751">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </>
  ),
});
