import { createIcon } from "@chakra-ui/icons";

export const LocationIcon = createIcon({
  displayName: "LocationIcon",
  defaultProps: {
    width: "40px",
    height: "40px",
  },
  viewBox: "0 0 40 40",
  path: (
    <>
      <g id="Frame">
        <g id="Group">
          <path
            id="Vector"
            d="M33.5336 27.5C33.2551 27.1552 32.8511 26.9352 32.4103 26.8883C31.9696 26.8414 31.5283 26.9715 31.1836 27.25C30.8388 27.5285 30.6188 27.9325 30.5719 28.3732C30.525 28.8139 30.6551 29.2552 30.9336 29.6C31.4138 30.181 31.6735 30.9129 31.6669 31.6667C31.6669 32.5507 31.3157 33.3986 30.6906 34.0237C30.0655 34.6488 29.2176 35 28.3336 35H11.6669C10.7828 35 9.935 34.6488 9.30988 34.0237C8.68476 33.3986 8.33357 32.5507 8.33357 31.6667C8.32697 30.9129 8.58668 30.181 9.0669 29.6C9.34538 29.2552 9.47549 28.8139 9.4286 28.3732C9.38172 27.9325 9.16168 27.5285 8.8169 27.25C8.47212 26.9715 8.03083 26.8414 7.59012 26.8883C7.14941 26.9352 6.74538 27.1552 6.4669 27.5C5.68212 28.4802 5.19012 29.6621 5.04752 30.9096C4.90493 32.1571 5.11756 33.4195 5.66091 34.5515C6.20427 35.6835 7.05627 36.6391 8.11881 37.3081C9.18136 37.9772 10.4113 38.3325 11.6669 38.3333H28.3336C29.5892 38.3325 30.8191 37.9772 31.8817 37.3081C32.9442 36.6391 33.7962 35.6835 34.3396 34.5515C34.8829 33.4195 35.0955 32.1571 34.9529 30.9096C34.8104 29.6621 34.3183 28.4802 33.5336 27.5Z"
            fill="currentColor"
          />
          <path
            id="Vector_2"
            d="M17.6504 30.95C18.3546 31.4165 19.1807 31.6654 20.0254 31.6654C20.8702 31.6654 21.6962 31.4165 22.4004 30.95C25.5338 28.8833 30.9504 24.2833 32.1504 17.0333C32.4687 15.122 32.3809 13.165 31.8927 11.2899C31.4045 9.41477 30.527 7.66346 29.3171 6.14996C28.2652 4.81916 26.9394 3.73008 25.4298 2.95654C23.9201 2.18301 22.2617 1.74309 20.5671 1.66662C18.9132 1.57242 17.2577 1.81975 15.7035 2.39323C14.1494 2.96671 12.73 3.85404 11.5338 4.99996C10.2916 6.1819 9.30702 7.60768 8.64166 9.18793C7.97629 10.7682 7.6445 12.4688 7.6671 14.1833C7.6671 23.3333 14.6504 28.9666 17.6504 30.95ZM13.8338 7.49996C15.4907 5.90359 17.6996 5.00807 20.0004 4.99996H20.4004C21.6242 5.06783 22.8186 5.40112 23.9007 5.97672C24.9829 6.55232 25.9268 7.35643 26.6671 8.33329C27.5994 9.48842 28.2737 10.8298 28.6445 12.2671C29.0154 13.7045 29.0742 15.2046 28.8171 16.6666C27.7838 22.9333 22.6671 26.9166 20.4838 28.3333C20.323 28.4394 20.1347 28.4959 19.9421 28.4959C19.7495 28.4959 19.5612 28.4394 19.4004 28.3333C16.8504 26.6666 10.9171 21.9166 10.9171 14.35C10.8991 13.068 11.1493 11.7964 11.6516 10.6167C12.1538 9.43711 12.8971 8.37548 13.8338 7.49996Z"
            fill="currentColor"
          />
          <path
            id="Vector_3"
            d="M20 19C20.9889 19 21.9556 18.7068 22.7779 18.1574C23.6001 17.6079 24.241 16.827 24.6194 15.9134C24.9978 14.9998 25.0969 13.9945 24.9039 13.0246C24.711 12.0546 24.2348 11.1637 23.5355 10.4645C22.8363 9.76521 21.9454 9.289 20.9755 9.09608C20.0055 8.90315 19.0002 9.00217 18.0866 9.3806C17.173 9.75904 16.3921 10.3999 15.8427 11.2222C15.2932 12.0444 15 13.0111 15 14C15 15.3261 15.5268 16.5979 16.4645 17.5355C17.4021 18.4732 18.6739 19 20 19ZM20 12.3333C20.3296 12.3333 20.6519 12.4311 20.926 12.6142C21.2 12.7974 21.4137 13.0577 21.5398 13.3622C21.6659 13.6667 21.699 14.0019 21.6346 14.3252C21.5703 14.6485 21.4116 14.9454 21.1785 15.1785C20.9454 15.4116 20.6485 15.5703 20.3252 15.6346C20.0018 15.699 19.6667 15.6659 19.3622 15.5398C19.0577 15.4137 18.7974 15.2 18.6142 14.926C18.4311 14.6519 18.3333 14.3296 18.3333 14C18.3333 13.558 18.5089 13.1341 18.8215 12.8215C19.134 12.5089 19.558 12.3333 20 12.3333Z"
            fill="currentColor"
          />
        </g>
      </g>
    </>
  ),
});
