import { createIcon } from "@chakra-ui/icons";

export const PriceIcon = createIcon({
  displayName: "PriceIcon",
  defaultProps: {
    width: "18px",
    height: "18px",
  },
  viewBox: "0 0 18 18",
  path: (
    <>
      <g clipPath="url(#clip0_1607_13387)">
        <path
          d="M7.24693 17.25C6.65039 17.2495 6.07848 17.0121 5.65693 16.59L1.41193 12.345C0.990575 11.9231 0.753906 11.3512 0.753906 10.755C0.753906 10.1587 0.990575 9.58686 1.41193 9.16499L6.99943 3.58499C7.15208 3.42664 7.33882 3.30517 7.54545 3.2298C7.75208 3.15442 7.97317 3.12713 8.19193 3.14998L10.2319 3.33748C10.4643 2.87759 10.7681 2.45739 11.1319 2.09248C12.7069 0.517485 15.0619 0.307485 16.3819 1.61249C17.7019 2.91748 17.4844 5.29498 15.9094 6.86248C15.5463 7.22635 15.1256 7.52788 14.6644 7.75499L14.8519 9.80248C14.871 10.021 14.8419 10.241 14.7668 10.447C14.6916 10.6531 14.5722 10.8401 14.4169 10.995L8.83693 16.5825C8.62881 16.7928 8.38125 16.9601 8.10841 17.0746C7.83558 17.1891 7.54283 17.2487 7.24693 17.25ZM8.05693 4.64248L2.46943 10.23C2.32974 10.3705 2.25133 10.5606 2.25133 10.7587C2.25133 10.9569 2.32974 11.147 2.46943 11.2875L6.75193 15.5325C6.89245 15.6722 7.08254 15.7506 7.28068 15.7506C7.47882 15.7506 7.6689 15.6722 7.80943 15.5325L13.3969 9.94499L12.9544 5.08498L11.3344 4.93498C11.3016 5.17646 11.323 5.4222 11.3969 5.6544C11.4709 5.8866 11.5955 6.09944 11.7619 6.27748C11.9032 6.41871 11.9825 6.61026 11.9825 6.80999C11.9825 7.00971 11.9032 7.20126 11.7619 7.34249C11.6207 7.48371 11.4292 7.56305 11.2294 7.56305C11.0297 7.56305 10.8382 7.48371 10.6969 7.34249C10.3672 7.01264 10.1158 6.6129 9.96128 6.17282C9.80679 5.73274 9.75319 5.26357 9.80443 4.79998L8.05693 4.64248ZM11.9044 3.48749L13.0519 3.59248C13.4012 3.62435 13.7283 3.77761 13.9763 4.02562C14.2243 4.27363 14.3776 4.6007 14.4094 4.94998L14.5144 6.09749C14.6297 6.00612 14.7399 5.90847 14.8444 5.80498C15.8419 4.81498 16.0519 3.40498 15.3244 2.66999C14.5969 1.93498 13.1869 2.16748 12.1969 3.15749C12.0934 3.26203 11.9958 3.3722 11.9044 3.48749Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1607_13387">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});
