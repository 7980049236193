import React from "react";
import ReactDOM from "react-dom/client";
//@ts-ignore
import ChakraProvider from "@/providers/ChakraProvider.jsx";
import RouteProvider from "@/providers/RouteProvider";
import AuthProvider from "@/providers/AuthProvider";
import "./styles/index.css";
import "./styles/fonts.css";
import QueryProvider from "./providers/QueryProvider";
import UtmProvider from "./providers/UtmProvider";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <QueryProvider>
    <AuthProvider>
      <ChakraProvider>
        <UtmProvider />
        <RouteProvider />
      </ChakraProvider>
    </AuthProvider>
  </QueryProvider>
);

// if ("serviceWorker" in navigator) {
//   const updateSW = registerSW({
//     onNeedRefresh() {
//       if (
//         import.meta.env.VITE_APP_VERSION !== +localStorage.getItem("version")
//       ) {
//         updateSW(true);
//       }
//     },
//   });
// }
