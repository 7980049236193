import { createIcon } from "@chakra-ui/icons";

export const WeightProductsIcon = createIcon({
  displayName: "WeightProductsIcon",
  defaultProps: {
    width: "32px",
    height: "32px",
  },
  viewBox: "0 0 32 32",
  path: (
    <g id="Frame">
      <g id="Group">
        <path
          id="Vector"
          d="M24.0002 1.33325H8.00016C7.12468 1.33325 6.25778 1.50569 5.44894 1.84072C4.6401 2.17575 3.90517 2.66682 3.28612 3.28587C2.03588 4.53612 1.3335 6.23181 1.3335 7.99992V23.9999C1.3335 24.8754 1.50593 25.7423 1.84097 26.5511C2.176 27.36 2.66706 28.0949 3.28612 28.714C4.53636 29.9642 6.23205 30.6666 8.00016 30.6666H24.0002C24.8756 30.6666 25.7425 30.4941 26.5514 30.1591C27.3602 29.8241 28.0952 29.333 28.7142 28.714C29.3333 28.0949 29.8243 27.36 30.1594 26.5511C30.4944 25.7423 30.6668 24.8754 30.6668 23.9999V7.99992C30.6668 7.12444 30.4944 6.25753 30.1594 5.4487C29.8243 4.63986 29.3333 3.90493 28.7142 3.28587C28.0952 2.66682 27.3602 2.17575 26.5514 1.84072C25.7425 1.50569 24.8756 1.33325 24.0002 1.33325ZM18.6668 3.99992V11.9999L16.7335 10.6666C16.5158 10.5232 16.2608 10.4468 16.0002 10.4468C15.7395 10.4468 15.4845 10.5232 15.2668 10.6666L13.3335 11.9999V3.99992H18.6668ZM28.0002 23.9999C28.0002 25.0608 27.5787 26.0782 26.8286 26.8283C26.0784 27.5785 25.061 27.9999 24.0002 27.9999H8.00016C6.9393 27.9999 5.92188 27.5785 5.17174 26.8283C4.42159 26.0782 4.00016 25.0608 4.00016 23.9999V7.99992C4.00016 6.93905 4.42159 5.92164 5.17174 5.17149C5.92188 4.42135 6.9393 3.99992 8.00016 3.99992H10.6668V11.9999C10.6677 12.4822 10.7994 12.9553 11.0479 13.3687C11.2964 13.7821 11.6523 14.1204 12.0778 14.3475C12.5033 14.5746 12.9825 14.6821 13.4642 14.6584C13.946 14.6348 14.4123 14.4809 14.8135 14.2133L16.0002 13.4266L17.1868 14.2133C17.5804 14.4679 18.0341 14.6143 18.5023 14.6377C18.9704 14.6611 19.4365 14.5607 19.8535 14.3466C20.2918 14.1289 20.6617 13.7948 20.9227 13.3809C21.1838 12.967 21.3259 12.4892 21.3335 11.9999V3.99992H24.0002C25.061 3.99992 26.0784 4.42135 26.8286 5.17149C27.5787 5.92164 28.0002 6.93905 28.0002 7.99992V23.9999Z"
          fill="currentColor"
        />
        <path
          id="Vector_2"
          d="M24.0002 22.6665H18.6668C18.3132 22.6665 17.9741 22.807 17.724 23.057C17.474 23.3071 17.3335 23.6462 17.3335 23.9998C17.3335 24.3535 17.474 24.6926 17.724 24.9426C17.9741 25.1927 18.3132 25.3332 18.6668 25.3332H24.0002C24.3538 25.3332 24.6929 25.1927 24.943 24.9426C25.193 24.6926 25.3335 24.3535 25.3335 23.9998C25.3335 23.6462 25.193 23.3071 24.943 23.057C24.6929 22.807 24.3538 22.6665 24.0002 22.6665Z"
          fill="currentColor"
        />
      </g>
    </g>
  ),
});
