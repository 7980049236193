import { createIcon } from "@chakra-ui/icons";

export const ImageIcon = createIcon({
  displayName: "ImageIcon",
  defaultProps: {
    width: "50px",
    height: "50px",
  },
  viewBox: "0 0 50 50",
  path: (
    <>
      <path
        d="M39.5833 6.25H10.4167C8.11548 6.25 6.25 8.11548 6.25 10.4167V39.5833C6.25 41.8845 8.11548 43.75 10.4167 43.75H39.5833C41.8845 43.75 43.75 41.8845 43.75 39.5833V10.4167C43.75 8.11548 41.8845 6.25 39.5833 6.25Z"
        stroke="currentColor"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.709 20.8333C19.4349 20.8333 20.834 19.4342 20.834 17.7083C20.834 15.9825 19.4349 14.5833 17.709 14.5833C15.9831 14.5833 14.584 15.9825 14.584 17.7083C14.584 19.4342 15.9831 20.8333 17.709 20.8333Z"
        stroke="currentColor"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.7494 31.25L33.3327 20.8333L10.416 43.75"
        stroke="currentColor"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});
